<template>
  <div>
    <the-app-bar />
    <!-- Hero section -->
    <div class="row py-24 px-12 oonpay-bg">
     <div class="container">
      <div class="row">
         <div class="col-md-6">
        <h4 class="font-semibold  text-oonpaytext text-3xl">
          Hi, we are here to help
        </h4>
        <p class="lg:text-xl font-semibold text-white">
          Oonpay is a trusted global direct payment network that connect you
          directly to service providers in Africa
        </p>
      </div>
      <div class="col-md-6"></div>
      </div>
     </div>
    </div>
    <div class="container  mt-5 space-y-10 my-8">
      <div class="row">
        <div class="col-md-12  col-xl-12 ">
          <!-- Nav tabs -->
          <div class="card">
            <div class="card-header">
              <ul class="nav nav-tabs justify-content-center" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                  >
                    About Oonpay
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                  >
                    HealthHub
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#foodhub"
                    role="tab"
                  >
                    FoodHub
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#messages"
                    role="tab"
                  >
                    Services Provider
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#settings"
                    role="tab"
                  >
                    Beneficiaries
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#payment"
                    role="tab"
                  >
                    Payments
                  </a>
                </li>
              </ul>
            </div>
            <div class="mt-5">
              <!-- Tab panes -->
              <div class="tab-content">
                <div class="tab-pane active" id="home" role="tabpanel">
                  <div id="accordion">
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <i class="icon">+ </i>
                            <b>What is Oonpay?</b>
                          </p>
                        </h5>
                      </div>

                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          Oonpay is a platform created to empowers Africans in
                          the diaspora to make direct-remittance local services
                          vendors and providers for services rendered to their
                          relatives and friends in Africa Oonpay digital digital
                          platform is designed with multiple services hubs
                          Starting with Healthhub which supports direct payment
                          to healthcare services providers . Foodhub provides a
                          marketplace where Africans in the diaspora can shop
                          for local wholesale food and pay and have it delivered
                          to their loved ones.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingTwo">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <i class="icon">+ </i>
                            <b>Why Use Oonpay?</b>
                          </p>
                        </h5>
                      </div>
                      <div
                        id="collapseTwo"
                        class="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          Oonpay is a one stop direct payment platform for
                          African in the diaspora to make direct payment to the
                          last mile (local vendors & services providers) for
                          services rendered to their family and friends'
                          resident in Africa. Oonpay reduce the time it takes
                          for business in Africa to receive and render services
                          for their global client. Oonpay create a transparent
                          and secure payment system that can be trusted by the
                          global community for payment to African businesses.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingThree">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i class="icon">+ </i>
                            <b
                              >How does Oonpay compare to other global money
                              transfer and remittance companies</b
                            >
                          </p>
                        </h5>
                      </div>
                      <div
                        id="collapseThree"
                        class="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          SMEs in major cities and remote African villages have
                          limited ways and means to accept digital payments.
                          Oonpay gives the unbanked (local vendors & services
                          providers) access to the global digital economy and
                          ability to accept digital payments
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="profile" role="tabpanel">
                  <div id="accordion">
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <i class="icon">+ </i>
                            <b>What is Oonpay Health Hub?</b>
                          </p>
                        </h5>
                      </div>

                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          Health related payments is time sensitive and about
                          75% of the unbanked in Africa doe not have health
                          Insurance coverage.Oonpay Healthhub supports direct
                          payment to healthcare services providers
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingTwo">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <i class="icon">+ </i>
                            <b>Why Use Oonpay Healthhub Services?</b>
                          </p>
                        </h5>
                      </div>
                      <div
                        id="collapseTwo"
                        class="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          Oonpay empowers Africans in the diaspora to make
                          direct-remittance to Hospitals, Clinics, Pharmacies,
                          Physical therapists and other allied health care
                          services providers E.g Emergencies medical bills can
                          be paid instantly by relatives from anywhere in the
                          world. doctor's consultations, prescriptions, lab
                          tests, optical care,
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingThree">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i class="icon">+ </i>
                            <b>What Services Are Provided Here ?</b>
                          </p>
                        </h5>
                      </div>
                      <div
                        id="collapseThree"
                        class="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          Hospital and medical care bills Home health visits
                          Send pre-paid vouchers for wellness check Instantt
                          remote medications payment Diagnostic services Dental
                          and eye care services
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="foodhub" role="tabpanel">
                  <div id="accordion">
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <i class="icon">+ </i>
                            <b>What is Food-Hub</b>
                          </p>
                        </h5>
                      </div>

                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          Oonpay food-hub is a one stop marketplace for African
                          in the diaspora to search and pay for wholesale food
                          items (bags of rice, grains, beans and lots more) for
                          their relatives and loved ones in Africa. local
                          services vendors and providers for services rendered
                          to their relatives and friends in Africa
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingTwo">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <i class="icon">+ </i>
                            <b>What type of food is provided </b>
                          </p>
                        </h5>
                      </div>
                      <div
                        id="collapseTwo"
                        class="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          Mostly wholesale items
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingThree">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i class="icon">+ </i>
                            <b>Can I pay for food from any Country</b>
                          </p>
                        </h5>
                      </div>
                      <div
                        id="collapseThree"
                        class="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          Yes you can pay for food items using your local
                          currency debit-credit cards in every country Oonpay
                          operates
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="messages" role="tabpanel">
                  <div id="accordion">
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <i class="icon">+ </i>
                            <b>Hospitals & Clinics</b>
                          </p>
                        </h5>
                      </div>

                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          All Local based Hosiptal and Clinics on Oonpay Network
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingTwo">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <i class="icon">+ </i>
                            <b>Pharmacies</b>
                          </p>
                        </h5>
                      </div>
                      <div
                        id="collapseTwo"
                        class="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          All Local based Pharmacies and Chemist on Oonpay
                          Network
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingThree">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i class="icon">+ </i>
                            <b>Therapists</b>
                          </p>
                        </h5>
                      </div>
                      <div
                        id="collapseThree"
                        class="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          All Local based Therapy centers on Oonpay Network
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingThree">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            <i class="icon">+ </i>
                            <b>Home-Health Care</b>
                          </p>
                        </h5>
                      </div>
                      <div
                        id="collapseFour"
                        class="collapse"
                        aria-labelledby="headingFour"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          This is a (weekly, monthly, or quarterly) Pre-paid
                          care plan designed to detect and prevent diseases.
                          Direct pre-paid health care beneficiaries will recieve
                          redeemable vouchers whenver they are visited by a
                          health professional at home. During this visit, there
                          will be a thorough check on your vitals. During which
                          the doctor's report and recommendation will
                          digitalized and shared.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingFour">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            <i class="icon">+ </i>
                            <b>Telemedicine</b>
                          </p>
                        </h5>
                      </div>
                      <div
                        id="collapseFive"
                        class="collapse"
                        aria-labelledby="headingFive"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          All Local based Doctors and Specialist on Oonpay
                          Network
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="settings" role="tabpanel">
                  <div id="accordion">
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <i class="icon">+ </i>
                            <b>Health-Hub Beneficiaries</b>
                          </p>
                        </h5>
                      </div>

                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          Once you have a phone you can become a beneficary on
                          via text messages , you can receive vouchers links and
                          present your vouchers at your specified Oonpay health
                          care services provider
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingTwo">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <i class="icon">+ </i>
                            <b>Health-Hub Beneficiaries #paytag</b>
                          </p>
                        </h5>
                      </div>
                      <div
                        id="collapseTwo"
                        class="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          Health care procedure can be paid for using the Oonpay
                          #paytag n advance by using the Oonpay patient unique
                          #paytag
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingThree">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i class="icon">+ </i>
                            <b>Health-Hub Beneficiaries QR-Code</b>
                          </p>
                        </h5>
                      </div>
                      <div
                        id="collapseThree"
                        class="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          The Oonpay Services Provider onsite QR-Code can be
                          used to make payments for admission fees, emergencies
                          medicantions and vials
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingThree">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            <i class="icon">+ </i>
                            <b>Health-Hub Beneficiaries Pre-Paid Packs</b>
                          </p>
                        </h5>
                      </div>
                      <div
                        id="collapseFour"
                        class="collapse"
                        aria-labelledby="headingFour"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          The Oonpay pre-paid pack vouchers is open for everyone
                          to use
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingFour">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            <i class="icon">+ </i>
                            <b>Food-Hub Beneficiaries</b>
                          </p>
                        </h5>
                      </div>
                      <div
                        id="collapseFive"
                        class="collapse"
                        aria-labelledby="headingFive"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          Oonpay food-hub is a one stop marketplace for African
                          in the diaspora to search and pay for wholesale food
                          items (bags of rice, grains, beans and lots more) for
                          their relatives and loved ones in Africa. local
                          services vendors and providers for services rendered
                          to their relatives and friends in Africa
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="tab-pane" id="payment" role="tabpanel">
                  <div id="accordion">
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <i class="icon">+ </i>
                            <b>How To Use The #paytag/Invoice</b>
                          </p>
                        </h5>
                      </div>

                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          Oonpay network partners- services providers (Hospital,
                          Clinics, Pharmacies ) generate instant invoices with
                          unique sharable #paytags that are associated with the
                          intended payee phone number and the intended payee (
                          benefactor) enters the #paytag on the Oonpay website
                          search form and is validated by an OTP to view and
                          make payment directly on the invoice using their local
                          currency debit, or credit card
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingTwo">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <i class="icon">+ </i>
                            <b>How To Use The Instant Payment QR_code</b>
                          </p>
                        </h5>
                      </div>
                      <div
                        id="collapseTwo"
                        class="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          Oonpay network partners- services providers (Hospital,
                          Clinics, Pharmacies ) generate QR-Codes for onsite
                          instant payment for Hospital Addmission, MRI,
                          CT-Scans, Blood Transfusion vials, Urgent Medication
                          and lots more.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingThree">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <i class="icon">+ </i>
                            <b>How To Use The Pre-Paid Vouchers</b>
                          </p>
                        </h5>
                      </div>
                      <div
                        id="collapseThree"
                        class="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          The Pre-Paid Voucher are provider specifics can only
                          be used with the services provider that in indicated
                          on the voucher
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingThree">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            <i class="icon">+ </i>
                            <b>Oonpay Mobile App payment</b>
                          </p>
                        </h5>
                      </div>
                      <div
                        id="collapseFour"
                        class="collapse"
                        aria-labelledby="headingFour"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          You can download the Oonpay Mobile App from the Google
                          play store or the Apple IOS store Once you sign up on
                          with your name, email address and phone number, you
                          can search for #paytags/ Invoices. and make instant
                          payments for your loved ones health related payments
                          and their daily food needs You can also opt for
                          automatic payments by adding a card so that payment
                          will be made automatically based on your payment
                          preference
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingFour">
                        <h5 class="mb-0">
                          <p
                            class="pl-3"
                            data-toggle="collapse"
                            data-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            <i class="icon">+ </i>
                            <b>Oonpay Website Platform payment</b>
                          </p>
                        </h5>
                      </div>
                      <div
                        id="collapseFive"
                        class="collapse"
                        aria-labelledby="headingFive"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          You can search for #paytags/ Invoices. and make
                          instant payments for your loved ones health related
                          payments and their daily food needs Oonpay network
                          partners- services providers (Hospital, Clinics,
                          Pharmacies ) generate instant invoices with unique
                          sharable #paytags that are associated with the
                          intended payee phone number and the intended payee (
                          benefactor) enters the #paytag on the Oonpay website
                          search form and is validated by an OTP to view and
                          make payment directly on the invoice using their local
                          currency debit, or credit card
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../../components/footer.vue";
import TheAppBar from "../../components/NavBar/TheAppBar.vue";
export default {
  components: { TheAppBar, Footer }
};
</script>

<style scoped>
p {
  line-height: 1.61em;
  font-weight: 300;
  font-size: 1.2em;
}

.category {
  text-transform: capitalize;
  font-weight: 700;
  color: #9a9a9a;
}

.now-ui-icons {
  display: inline-block;
  font: normal normal normal 14px/1 "Nucleo Outline";
  font-size: inherit;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.oonpay-bg {
  background: #0c4369;
}

@-webkit-keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes nc-icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.icon {
  font-size: 30px;
}

.nav-tabs {
  border: 0;
  padding: 15px 0.7rem;
}

.card .nav-tabs {
  border-top-right-radius: 0.1875rem;
  border-top-left-radius: 0.1875rem;
}

.nav-tabs > .nav-item > .nav-link {
  color: #888888;
  margin: 0;
  margin-right: 5px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 30px;
  font-size: 14px;
  padding: 11px 23px;
  line-height: 1.5;
}

.nav-tabs > .nav-item > .nav-link:hover {
  background-color: transparent;
}

.nav-tabs > .nav-item > .nav-link.active {
  background-color: #444;
  border-radius: 30px;
  color: #ffffff;
}

.nav-tabs > .nav-item > .nav-link i.now-ui-icons {
  font-size: 14px;
  position: relative;
  top: 1px;
  margin-right: 3px;
}

.nav-tabs.nav-tabs-neutral > .nav-item > .nav-link {
  color: #ffffff;
}

.nav-tabs.nav-tabs-neutral > .nav-item > .nav-link.active {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}

.card {
  border: 0;
  border-radius: 0.1875rem;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}

.card .card-header {
  background-color: transparent;
  border-bottom: 0;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
}

.card[data-background-color="orange"] {
  background-color: #f96332;
}

.card[data-background-color="red"] {
  background-color: #ff3636;
}

.card[data-background-color="yellow"] {
  background-color: #ffb236;
}

.card[data-background-color="blue"] {
  background-color: #2ca8ff;
}

.card[data-background-color="green"] {
  background-color: #15b60d;
}

[data-background-color="orange"] {
  background-color: #e95e38;
}

[data-background-color="black"] {
  background-color: #2c2c2c;
}

[data-background-color]:not([data-background-color="gray"]) {
  color: #ffffff;
}

[data-background-color]:not([data-background-color="gray"]) p {
  color: #ffffff;
}

[data-background-color]:not([data-background-color="gray"])
  a:not(.btn):not(.dropdown-item) {
  color: #ffffff;
}

[data-background-color]:not([data-background-color="gray"])
  .nav-tabs
  > .nav-item
  > .nav-link
  i.now-ui-icons {
  color: #ffffff;
}

@font-face {
  font-family: "Nucleo Outline";
  src: url("https://github.com/creativetimofficial/now-ui-kit/blob/master/assets/fonts/nucleo-outline.eot");
  src: url("https://github.com/creativetimofficial/now-ui-kit/blob/master/assets/fonts/nucleo-outline.eot")
    format("embedded-opentype");
  src: url("https://raw.githack.com/creativetimofficial/now-ui-kit/master/assets/fonts/nucleo-outline.woff2");
  font-weight: normal;
  font-style: normal;
}

.now-ui-icons {
  display: inline-block;
  font: normal normal normal 14px/1 "Nucleo Outline";
  font-size: inherit;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 768px) {
  .nav-tabs {
    display: inline-block;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
    text-align: center;
  }

  .nav-tabs .nav-item > .nav-link {
    margin-bottom: 5px;
  }
}

.bg-warning {
  background: #cfd8df !important;
}

img.center {
  display: block;
  margin: 0 auto;
}
</style>
